import {Component, ChangeDetectionStrategy, ChangeDetectorRef, isDevMode, OnDestroy, ViewChild, ViewContainerRef, inject} from '@angular/core';
import {firstValueFrom, Subject} from 'rxjs';
import {ConvertionService} from 'app/core/services/convertion.service';
import {default as LanguageFile} from './language.file.json';
import {SupportService} from './core/services/support.service';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {TranslocoService, translate} from '@jsverse/transloco';
import {marker} from '@nyffels/transloco-keys-manager/marker';
import {TranslocoChangedEvent} from './shared/logic/system/translation.logic';
import {getWhitelabelEnvironment, getWhitelabelName} from "./core/logic/whitelabel.logic";
import {GraphqlService} from "./core/services/graphql.service";
import {Router, RouterOutlet} from "@angular/router";
import {CommonModule} from "@angular/common";
import {FaIconLibrary} from "@fortawesome/angular-fontawesome";
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {ProgressBarModule} from "primeng/progressbar";
import {TranslationService} from "./core/services/translation.service";
import {ViewRegistry} from "./core/registries/view.registry";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, CommonModule, ProgressBarModule]
})
export class AppComponent implements OnDestroy {
  @ViewChild('notificationsArea', {read: ViewContainerRef}) notificationArea;

  private readonly cdr = inject(ChangeDetectorRef);
  private readonly convertionService = inject(ConvertionService);
  private readonly supportService = inject(SupportService);
  private readonly httpClient = inject(HttpClient);
  private readonly titleService = inject(Title);
  private readonly translocoService = inject(TranslocoService);
  private readonly vcr = inject(ViewContainerRef);
  private readonly graphqlService = inject(GraphqlService);
  private readonly library = inject(FaIconLibrary);
  private readonly translationService = inject(TranslationService);
  private readonly viewRegistry = inject(ViewRegistry);

  title = 'DOffice';
  errorMessage: string | null = null;

  startup: boolean = true; // Is the program starting up? Show splash screen, if done start application.
  isDev: boolean = false;

  private _destroy$: Subject<void> = new Subject();

  constructor() {
    this.library.addIconPacks(fas, far);
    this.supportService.start();
    this.isDev = isDevMode();

    this.viewRegistry.rootContainerRef.set(this.vcr);

    this.httpClient.get('assets/version', {responseType: 'text'})
      .subscribe({
        next: (data) => {
          console.log('Version: ' + data);
          const name = getWhitelabelEnvironment().name;
          this.titleService.setTitle(name);
          (document.querySelector('#favIcon') as any).href = `assets/whitelabels/${getWhitelabelName()}/favicon.ico`;
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.warn('VERSION NOT FOUND!');
          this.cdr.detectChanges();
        },
      });

    this.convertionService.settings = {
      dateFormat: 'DD/MM/YYYY',
      dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
      timezone: 'Europe/Brussels',
      moneySettings: {
        decimals: 2,
        symbol: '€',
        symbolLocation: 'BEFORE',
      },
      numberSettings: {
        decimalSeperationSymbol: ',',
        digitSeperationSymbol: '.',
      },
    };
    this.translationService.loadData(LanguageFile, false);

    this.translationService.language = navigator.language.substring(0, 2);
    this.translationService.defaultIso = 'nl';
    this.translocoService.load(this.translationService.language)
      .subscribe();
    this.translocoService.setActiveLang(this.translationService.language);
    TranslocoChangedEvent(this.translocoService)
      .subscribe(() => {
        this.startupProcess()
          .then((completed) => {
            if (completed == null) {
              this.errorMessage = translate(marker('error.init.databaseConnectionFailed', 'Verbinding met de database mislukt. Controleer de internet verbinding en firewall. We herladen de applicatie in 10 seconden. Als je deze melding blijft krijgen, contacteer support.'));
              setTimeout(() => {
                window.location.reload();
              }, 10000);
            }
            if (completed == true) {
              this.startup = false;
            }

            this.cdr.detectChanges();
          });
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$.unsubscribe();
  }

  private async startupProcess(): Promise<Boolean> {
    let startup = await firstValueFrom(this.graphqlService.queries.validateDatabaseConnection())
      .catch(err => {});
    if (startup == null) {
      return null;
    }

    return true;
  }

  getLogo() {
    return getWhitelabelEnvironment().app.logo
  }
}
