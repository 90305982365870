<div class="startup-container animate-delay" *ngIf="startup">
  <div class="loading-indication text-center">
    <img class="startup-logo" [src]="getLogo()"/><br/>
    <p-progressBar styleClass="" mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
    @if (errorMessage) {
      <small class="text-red-500">{{ errorMessage }}</small>
    }
  </div>
</div>
<div *ngIf="!startup">
  <router-outlet/>
</div>
